@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import "https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Poppins:400,500,600&display=swap";


body {
  font-family: 'Montserrat', 'Yeseva One', 'Roboto', sans-serif ;
}

.navbar-nav li a{
  font-family: 'Montserrat';
  font-weight: 700;
  color: #000000;
}

.navbar-nav li a:hover{
  font-family: 'Montserrat';
  font-weight: 700;
  color: #008000;
}

.navbar-nav .dropdown button{
  font-family: 'Montserrat';
  font-weight: 700;
  color: #000000;
}

.navbar-nav .dropdown button:hover{
  font-family: 'Montserrat';
  font-weight: 700;
  color: #008000;
}

.navbar-nav .dropdown ul li a{
  font-family: 'Montserrat';
  font-weight: 400;
}

.navbar-nav .dropdown ul li a:focus{
  background-color: #ffffff;
}

.active{
  font-family: 'Montserrat';
  font-weight: 700;
  color: #ffd700 !important;
}

.main-banner {
  padding: 5rem 0;
}

.form-label{
  font-weight: 700;
}



.my-table th,
.my-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.my-table th {
  background-color: #f4f4f4;
}

.my-table h2 {
  color: #333;
}


.lg-label{
  font-weight: 500;
}
.tagline h1{
  font-family:'Montserrat';
  font-weight: 700;
  font-size: 60px;
}

/* .btn{
  border-radius: 0 !important;
} */

.btn-success{
  background-color: #008000 !important;
  /* padding: 0.7rem; */
}

.btn-warning{
  background-color: #ffd700 !important;
}

.banner-image img{
  border-bottom-left-radius: 100px;
  border-top-right-radius: 100px;
}

img {
  max-width: 100%;
}

.about-section {
  background: #0d3642;
  position: relative;
  padding-bottom: 300px;
  z-index: -1;
}

.about-section::before {
  position: absolute;
  content: "";
  background-image: url(/src/assets/img/video-shape.png);
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.about-sub{
  margin-top: -200px;
  padding: 3rem 3rem 0 3rem;
}

.about-sub h2{
  font-family:'Montserrat';
  font-weight: 700;
  font-size: 35px;
}

.action img{
  margin-top: -80px;
}

.why-choose h2{
    font-family:'Montserrat';
    font-weight: 700;
    font-size: 35px;
}

.services h2{
  font-family:'Montserrat';
  font-weight: 700;
  font-size: 35px;
}

.services{
  background-color: #F6F5FC;
  padding: 50px 0px;
}

.services .card{
  background-color: transparent;
}
.services .card .card-img-top {
  object-fit: cover; 
  height: 200px;
  width: 100%; 
}

.services-main .card .card-img-top {
  object-fit: cover; 
  height: 200px;
  width: 100%; 
}


.testimonials h2{
  font-family:'Montserrat';
  font-weight: 700;
  font-size: 35px;
}

.testimonials .client .image{
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.testimonials .client .image img{
  object-fit: cover; 
  height: 50px;
  width: 100%;
  border-radius: 50%;
}

.footer{
  background-color: #0d3642;
  padding: 15px 0;
}

/* Scroll to top  */
.floating-button {
  position: fixed;
  bottom: 100px;
  right: 40px;
  background-color: #008000;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

.floating-button.visible {
  opacity: 1;
  visibility: visible;
}

.floating-button:hover {
  background-color: #008000;
}

/* Preloader */
/* Preloader container */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it's on top of other content */
}

/* Spinner styling */
.spinner {
  position: absolute;
  width: 100px; /* Size of the spinner */
  height: 100px; /* Size of the spinner */
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-top: 10px solid #008000; /* Color of the spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Animation for the spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Logo styling */
.logo {
  position: relative;
  z-index: 1; /* Ensures the logo is above the spinner */
  width: 100px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
}


.about-banner {
  position: relative;
  background-image: url('/src/assets/img/FINANCIALANALYSIS2.jpg');
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  height: 300px; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white; 
}

.about-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1; 
}

.about-banner h1 {
  font-family:'Montserrat';
  font-weight: 700;
  font-size: 60px;
  position: relative;
  z-index: 2;
  margin: 0;
}

.about-banner h2 {
  font-family:'Montserrat';
  font-weight: 700;
  font-size: 40px;
  position: relative;
  z-index: 2;
  margin: 0;
}

.team h2{
  font-family:'Montserrat';
  font-weight: 700;
  font-size: 35px;
}

.exp {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.exp:hover {
  background-color: #ffd700;
  transform: translateY(-10px);
}
.pre {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.pre:hover {
  background-color: #0d3642;
  transform: translateY(-10px);
  color: #ffffff;
}

.timely {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.timely:hover {
  background-color: #008000;
  transform: translateY(-10px);
  color: #ffffff;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: relative;
  background-color: #282c34;
  background-image: url('/src/assets/img/FINANCIALANALYSIS2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin) !important;
  color: white !important;
  z-index: 1;
}

.App-header p{
  font-size: calc(10px + 2vmin) !important;
  color: white !important;
}

.App-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.719); 
  z-index: -1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 767px) {
  .main-banner {
    padding: 2rem 0;
  }
  .tagline{
    text-align: center;
  }
  .tagline h1{
    font-family:'Montserrat';
    font-weight: 700;
    font-size: 25px;
  }
  .banner-image{
    text-align: center;
  }
  .about-sub {
    text-align: center;
    padding: 2rem 1rem 0 1rem;
  }
  .about-sub h2{
    font-family:'Montserrat';
    font-weight: 700;
    font-size: 20px;
  }
  .action img{
    margin-top: 15px;
  }
  .about-banner{
    height: 200px;
  }
 
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-banner {
    padding: 2rem 0;
  }
  .tagline{
    text-align: center;
  }
  .tagline h1{
    font-family:'Montserrat';
    font-weight: 700;
    font-size: 25px;
  }
  .banner-image{
    text-align: center;
  }
  .about-sub {
    text-align: center;
    padding: 2rem 1rem 0 1rem;
  }
  .about-sub h2{
    font-family:'Montserrat';
    font-weight: 700;
    font-size: 20px;
  }
  .action img{
    margin-top: 15px;
  }
  .about-banner{
    height: 200px;
  }
  
}